























































import { Component, Vue } from 'vue-property-decorator'
import { teamsStore, contactsStore, billingStore } from '@/store'
import UserControls from '@/components/HeaderBar/UserControls.vue'
import { openURL } from 'quasar'
import { preventHtmlTags } from '@/utils'
import i18n from '@/i18n'

@Component({
  components: {
    Icon402: () => import('@/components/UI/icons/Icon402.vue'),
    TdBtn: () => import('td-ui/dist/TdBtn.vue'),
    UserControls,
  },
})
export default class Blocking extends Vue {
  created () {
    const team = teamsStore.getters.currentTeam
    contactsStore.actions.updateContact(team.me)
  }

  get description () {
    const team = teamsStore.getters.currentTeam
    const teamName = preventHtmlTags(team.name)
    return this.isOwner
      ? this.$t('blocking.descriptionOwner', { teamName })
      : this.$t('blocking.description', {
        teamName,
      })
  }

  get description2 () {
    const team = teamsStore.getters.currentTeam
    const ownerName = team.owner?.displayName
    return this.isOwner
      ? this.$t('blocking.descriptionOwner2')
      : this.$t('blocking.description2', {
        ownerName,
      })
  }

  get isOwner () {
    return teamsStore.getters.currentTeam.me.status === 'owner'
  }

  get isAdmin () {
    return teamsStore.getters.currentTeam.me.status === 'admin'
  }

  // get teamName () {
  //   return teamsStore.getters.currentTeam.name
  // }

  // get teamUid () {
  //   return teamsStore.getters.currentTeam.uid
  // }

  get personalAccountId () {
    return billingStore.state?.account?.personalAccountId || '111'
  }

  // get ownerUuid () {
  //   return billingStore.state?.account?.ownerUuid || '222'
  // }

  get titleMail () {
    return this.$t('blocking.subjectLK')
  }

  openLK () {
    if (this.$q.platform.is.electron) {
      location.href = `${location.origin}/console/account/main`
    } else {
      window.open(`${location.origin}/console/account/main`)
    }
  }

  openFAQ () {
    openURL('https://tdwiki.notion.site/FAQ-2d90114f90bc48a39851edccc0421f7d#ab1c731cba7f4e7d9e0d14a0ff9ee513')
  }
}
